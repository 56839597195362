/* eslint-disable tailwindcss/no-arbitrary-value */
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Icon from 'molecules/Icon';

import type { ButtonProps } from 'molecules/Button';
import type { ChangeEventHandler, ComponentPropsWithoutRef, FC } from 'react';

const inputStyles = cva(
  [
    'cursor-pointer',
    'text-md',
    'bg-white',
    'text-gray-900',
    'w-full',
    'py-2.5',
    'px-3',
    'border',
    'border-gray-300',
    'shadow-xs',
    'outline-none',
  ],
  {
    variants: {
      hasIcon: {
        true: ['pl-10'],
      },
      disabled: {
        true: ['text-gray-500', 'cursor-not-allowed'],
      },
      error: {
        true: [
          '!border-error-700',
          'focus:shadow-[0px_0px_0px_2px_#F04438,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]',
          'placeholder-error-700',
        ],
        false: [
          'outline-none focus:!border-primary-600 focus:shadow-[0px_0px_0px_2px_#79C300,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]',
        ],
      },
    },
  },
);

const arrowStyles = cva(['appearance-none bg-white bg-right bg-no-repeat pr-8']);

const helper = cva(['text-gray-600', 'text-xs', 'mt-1'], {
  variants: {
    error: {
      true: ['text-error-700', 'font-semibold'],
    },
  },
});

export const dropdownChevron = cva(
  [
    'pointer-events-none',
    'absolute',
    'top-3',
    'right-3',
    'origin-center',
    'transition-transform',
    'duration-300',
    'ease-in-out',
  ],
  {
    variants: {
      type: {
        menu: ['fill-gray-900'],
        input: ['fill-primary-600'],
        filter: ['fill-primary-600'],
      },
      isOpen: {
        true: ['rotate-0'],
        false: ['rotate-180'],
      },
      error: {
        true: ['fill-error-700'],
      },
    },
  },
);

export interface InputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  name?: string;
  className?: string;
  label?: string;
  labelHidden?: boolean;
  defaultValue?: string;
  helperText?: string | false;
  error?: boolean;
  errorMessage?: string;
  options?: { label: string; value: string }[];
  unselectedLabel?: string;
  button?: ButtonProps;
  type?: 'input' | 'menu' | 'filter';
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const Select: FC<InputProps> = ({
  name,
  className,
  label,
  labelHidden,
  options,
  unselectedLabel,
  helperText,
  error,
  errorMessage,
  value,
  defaultValue,
  disabled,
  type = 'input',
  onChange,
}) => {
  const [currValue, setCurrValue] = useState(defaultValue || '');

  useEffect(() => {
    if (value) {
      setCurrValue(value);
    }
  }, [value]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const targetValue = event.target.value;
    setCurrValue(targetValue as string);
  };

  return (
    <div className={twMerge('w-full mb-4', className)}>
      {!labelHidden && label && (
        <label htmlFor={name} className="text-sm font-semibold text-gray-700">
          {label}
        </label>
      )}
      <div className="input relative mt-1">
        <select
          name={name}
          className={twMerge(inputStyles({ disabled, error }), arrowStyles())}
          disabled={disabled}
          value={currValue}
          onChange={e => {
            handleChange(e);
            onChange && onChange(e);
          }}
        >
          <option value="">{unselectedLabel || 'Please Select'}</option>
          {options?.map((option, index) => (
            <option key={`select-options-${index + 1}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <Icon icon="chevron-down" size={20} className={twMerge(dropdownChevron({ type, error }))} />
      </div>
      {(helperText || error) && <p className={twMerge(helper({ error }))}>{helperText || errorMessage}</p>}
    </div>
  );
};

export default Select;

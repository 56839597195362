import axios from 'axios';
import { navigate } from 'gatsby';
import { useEffect, useRef, useState } from 'react';
import twMerge from 'twMerge';

import ChiliPiperWidget from 'components/Form/ChilipiperWidget';
import formStyles, {
  headingContent,
  headingSubhead,
  hubspotFormStyles,
  thankyouMessage,
} from 'components/Form/Form.styles';
import { HubspotFormFields, PrivacyPolicy, Submit } from 'components/Form/FormFields';
import useHubspotFormData from 'components/Form/useHubspotFormData';
import {
  enableHoneypot,
  findField,
  getInitValues,
  getUserHutk,
  isFieldVisible,
  prepareFormSubmissionValues,
  setGTMData,
  validateEmail,
} from 'components/Form/utils/functions';

import parseUrl from 'utils/parseUrl';
import richTextParser from 'utils/richTextParser/richTextParser';

import type { HubSpotField } from 'components/Form/utils/functions';
import type { ContentfulComponentForm } from 'graphqlTypes';
import type { FC, SyntheticEvent } from 'react';
import type { RichText } from 'utils/richTextParser/richTextParser';

export interface FormProps
  extends Omit<ContentfulComponentForm, 'children' | 'contentful_id' | 'id' | 'internal' | 'node_locale'> {
  className?: string;
  sectionID?: string;
  isSubscribe?: boolean;
  isInline?: boolean;
  onSuccess?: () => void;
}

declare global {
  interface Window {
    hbspt: Record<string, any>;
  }
}

const MAIN_DEMO_ID = '298df13e-582e-4caa-ad47-c9656083e724';

const HubspotForm: FC<FormProps> = ({
  className,
  heading,
  subhead,
  formId,
  isSubscribe,
  isInline,
  chiliPiperRouter,
  redirectUrl,
  customSuccessMessage,
  onSuccess,
}) => {
  const [isDuplicateUser, setIsDuplicateUser] = useState(false);
  const [subscribeInputValue, setSubscribeInputValue] = useState(false);

  const form = useHubspotFormData()?.find(x => x.guid === formId);
  const hubspotPortalId = form?.portalId;
  const idForm = `${formId}${isInline ? '-inline' : ''}`;

  const formRef = useRef<HTMLDivElement>(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [values, setValues] = useState({});
  const [validation, setValidation] = useState({});
  const [hasValidatedOnce, setValidatedOnce] = useState<boolean>(false);
  const [formSubmission, setFormSubmission] = useState(false);

  const [chiliPiperData, setChiliPiperData] = useState<any>({});
  const [hasHandledCP, setHandledCP] = useState(!chiliPiperRouter);

  const privacyPolicyHTML = form
    ? JSON.parse(form.metaData.find(x => x.name === 'legalConsentOptions')?.value || '{}')?.privacyPolicyText
    : '';

  const isFieldInvalid = (value: any, name: string) => {
    const isRequired = findField(form, name).required;

    if (!isRequired) {
      return false;
    }
    if (name === 'email') {
      return !(value && validateEmail(value));
    }
    if (name.includes('phone')) {
      return value?.length !== 14;
    }

    return value === undefined || value === null || value === '';
  };

  const handleChange = (e: SyntheticEvent) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleBlur = (e: SyntheticEvent) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setValidation({ ...validation, [e.target.name]: isFieldInvalid(e.target.value, e.target.name) });
  };

  const handleAllFieldsValidation = () => {
    const newValidation = Object.keys(values).reduce(
      (accumulator, key) => {
        const field = findField(form, key);
        accumulator[key] = field.required && isFieldVisible(field, values) ? isFieldInvalid(values[key], key) : false;

        return accumulator;
      },
      { ...validation },
    );

    const isFormValid = Object.keys(newValidation).every(key => !newValidation[key]);

    setValidation(newValidation);
    setValidatedOnce(!hasValidatedOnce);

    return isFormValid;
  };

  const handleHubspotRedirect = () => {
    if (redirectUrl?.redirectUrl) {
      navigate(redirectUrl.redirectUrl);
    }
    if (form.redirect) {
      const { to, href } = parseUrl(form.redirect);
      const redirect = to || href;
      redirect && navigate(redirect);
    }
  };

  const handleDemoRouting = () => {
    const { revenue_segment, industry__dropdown_, other_industries___primary_industries } = values || {};
    if (revenue_segment === 'Less than 1M') {
      navigate('/demo/cc/not-booked');
    } else if (
      industry__dropdown_ === 'Other' &&
      ['Pest Control', 'Paving', 'Concrete'].includes(other_industries___primary_industries)
    ) {
      navigate('/demo/cc/not-booked');
    } else {
      navigate('/demo/as/not-booked');
    }
  };

  const handleDisplaySubscribeDisclaimer = () => {
    const currentForm = formRef?.current;
    const input = currentForm?.querySelector('[name="email"]');

    input?.addEventListener(
      'input',
      e => {
        handleChange(e);
        setSubscribeInputValue(true);
      },
      { once: true },
    );
  };

  let subscribeFormDisclaimerClass = '';

  if (isSubscribe) {
    subscribeFormDisclaimerClass = subscribeInputValue ? 'show-disclaimer' : 'hide-disclaimer';
  }

  const handleFormInit = () => {
    const initValues = getInitValues(form);
    const hutk = getUserHutk();

    if (!hutk) {
      setValues(initValues);

      return;
    }

    try {
      const valuesToSet = initValues;
      const userPropsPromise = axios.get(`${process.env.GATSBY_API_URL}/hubspot/aspire/contact/utk/${hutk}/profile`);
      userPropsPromise
        .then(res => {
          const userProps = res?.data?.properties;

          if (userProps) {
            Object.keys(initValues).forEach(k => {
              if (userProps[k]?.value && isFieldVisible(findField(form, k), initValues)) {
                valuesToSet[k] = userProps[k].value;
              }
            });
          }
          setValues(valuesToSet);
        })
        .catch(err => {
          console.log(err);
          setValues(initValues);
        });
    } catch (err) {
      console.log(err);
      setValues(initValues);
    }
  };

  useEffect(() => {
    if (!form) {
      return;
    }

    handleFormInit();

    if (isSubscribe) {
      handleDisplaySubscribeDisclaimer();
    }
  }, []);

  useEffect(() => {
    // Get IP Address
    axios
      .get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response?.data?.ip || undefined);
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (formRef.current && form) {
      enableHoneypot(formRef.current);
    }
    window?.dataLayer?.push({ event: 'hubspot_form_ready' });
  }, [formRef, form]);

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data['action'] === 'close') {
        setHandledCP(true);
      }
    });
    const reset = () => {
      setChiliPiperData({});
      setFormSubmission(false);
      setValidatedOnce(false);
      setHandledCP(!chiliPiperRouter);
    };

    window.addEventListener('pageshow', event => {
      if (event.persisted) {
        reset();
      }
    });

    return () => {
      window.removeEventListener('pageshow', event => {
        if (event.persisted) {
          reset();
        }
      });
      reset();
    };
  }, []);

  const handlePropertyIntelFormSubmission = () => {
    if (formId === '307b3e7a-c92f-4d7a-a186-1af3625fa56b') {
      const { company, firstname, lastname, email, phone, actual_crews, pi_qualification_are_you_a } = values;
      const apiData = {
        display_name: company,
        first: firstname,
        last: lastname,
        email,
        phone,
        organization_type: pi_qualification_are_you_a,
        crew_count: actual_crews,
      };

      axios
        .post('https://pi.youraspire.com/api/v1/organizations', apiData)
        .then(response => {
          if (response.data.url) {
            if (response.data.url === '/duplicate_user') {
              setIsDuplicateUser(true);
            } else {
              navigate(response.data.url);
            }
          }
        })
        .catch(error => {
          console.error('Error submitting to PropertyIntel:', error);
        });
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!handleAllFieldsValidation()) {
      return;
    }

    const honeypot = formRef?.current?.querySelector('[name="honeypot"]');
    if (honeypot?.value) {
      return;
    }

    // Setup form values for HubSpot submission
    const fieldsData: { name: string; value: any }[] = prepareFormSubmissionValues(form, values);

    // Add hidden field values from Local Storage
    setGTMData(fieldsData);

    const hutk = getUserHutk();

    const postData = {
      fields: fieldsData,
      context: {
        ...(hutk ? { hutk } : {}),
        ...(ipAddress ? { ipAddress } : {}),
        pageUri: document.baseURI,
        pageName: document.title,
      },
    };

    const setCPArray = (data: [{ name: string; value: any }]) => {
      const newChiliPiperData: { [key: string]: string } = {};
      for (const key in data) {
        const cpName = newChiliPiperData[data[key].name];
        newChiliPiperData[data[key].name] = cpName ? `${cpName};${data[key].value}` : data[key].value;
      }
      setChiliPiperData(newChiliPiperData);
    };
    setCPArray(postData.fields);

    window?.dataLayer?.push({
      event: 'hubspot_form_submit',
      analytics: {
        formdata: {
          ...fieldsData?.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {}),
          form_id: formId,
        },
      },
    });

    try {
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${formId}`;
      axios
        .post(url, postData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          setFormSubmission(true);
          handlePropertyIntelFormSubmission();

          onSuccess && onSuccess(postData);

          if (values.form_type === 'Demo Request' || values.form_type === 'Product Match Form') {
            handleDemoRouting();

            return;
          }

          if (!chiliPiperRouter) {
            handleHubspotRedirect();
          }
        })
        .catch((err: any) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  const bottomComponents = [
    <Submit key="Submit" handleSubmit={handleSubmit} label={form?.submitText || 'Submit'} />,
    <PrivacyPolicy key="PrivacyPolicy" privacyPolicyHTML={privacyPolicyHTML} />,
  ];

  const ThankYouMessage = () => {
    if (isDuplicateUser) {
      return (
        <div className="flex flex-col gap-6">
          <p className="text-md">
            Uh oh, it looks like you have already had a free trial with this email. To request more free time, please
            speak with one of our team members. To request more free time, please send us an email at{' '}
            <b>propertyintelligence@youraspire.com</b>.
          </p>
        </div>
      );
    }
    if (customSuccessMessage) {
      return <div className="flex flex-col gap-6">{richTextParser(customSuccessMessage as RichText)}</div>;
    }
    if (hasHandledCP && form.inlineMessage) {
      return <div className={twMerge(thankyouMessage())} dangerouslySetInnerHTML={{ __html: form.inlineMessage }} />;
    }

    return null;
  };

  return (
    <div className={`${twMerge(className, formStyles())} ${subscribeFormDisclaimerClass}`}>
      {heading && <h2 className={twMerge(headingContent())}>{heading}</h2>}
      {subhead && <p className={twMerge(headingSubhead())}>{richTextParser(subhead as RichText)}</p>}
      {formSubmission && <ThankYouMessage />}
      {formId && !formSubmission && (
        <div
          className={twMerge(
            '@container',
            'form-container',
            hubspotFormStyles({
              isSubscribe,
              isInline,
              hidden: formSubmission && !!customSuccessMessage,
              mainDemoForm: formId === MAIN_DEMO_ID,
            }),
          )}
          id={idForm}
        >
          <form ref={formRef} data-form_type={values?.form_type} noValidate>
            {form.formFieldGroups.map((fieldGroup: { fields: HubSpotField[] }, index: number) => (
              <>
                <HubspotFormFields
                  key={`hs-field-${index}`}
                  fieldGroup={fieldGroup.fields}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  validation={validation}
                  isSubscribe={isSubscribe}
                />
                {fieldGroup?.fields?.map(field =>
                  field?.dependentFieldFilters?.map((x, index2: number) => (
                    <HubspotFormFields
                      key={`hs-field-${index2}`}
                      fieldGroup={[
                        {
                          ...x.dependentFormField,
                          filters: { ...x.filters, key: field.name },
                        },
                      ]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      validation={validation}
                      isSubscribe={isSubscribe}
                    />
                  )),
                )}
              </>
            ))}
            {bottomComponents.map(x => x)}
          </form>
        </div>
      )}
      {chiliPiperRouter && !hasHandledCP && formSubmission && (
        <ChiliPiperWidget values={chiliPiperData} cpRouterName={chiliPiperRouter} onError={() => setHandledCP(true)} />
      )}
    </div>
  );
};

export default HubspotForm;

import 'styles/global.css';
import 'styles/hubspotForm.css';
import { AnnouncementProvider } from 'context/AnnouncementContext';
import smoothscrollAnchor from 'smoothscroll-anchor-polyfill';
import smoothscroll from 'smoothscroll-polyfill';

import GlobalModalForm from 'components/Form/GlobalModalForm';

export const onClientEntry = () => {
  smoothscroll.polyfill();
  smoothscrollAnchor.polyfill();
};

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <AnnouncementProvider>
    <GlobalModalForm />
    {element}
  </AnnouncementProvider>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  const floatingCampaign = document.querySelector('.CampaignType--floating');

  const setHeaderPosition = () => {
    const headerContainerEle = document.querySelector('header');

    if (headerContainerEle) {
      headerContainerEle.style.top = floatingCampaign.offsetHeight + 'px';
    }
  };

  if (floatingCampaign) {
    setHeaderPosition();
    document.addEventListener('om.Campaign.afterShow', () => {
      setHeaderPosition();
    });
  }

  if (!prevLocation && !location?.hash) {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  window.history.scrollRestoration = 'manual';
  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  if (location.hash) {
    const item = document.querySelector(`${location.hash}`);
    item?.scrollIntoView({ behavior: 'instant' });
  } else {
    window.scrollTo({
      top: currentPosition[1],
      left: currentPosition[0],
      behavior: 'instant',
    });
  }

  return false;
};

/* eslint-disable tailwindcss/no-arbitrary-value */
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import Button from 'molecules/Button';
import Icon from 'molecules/Icon';

import type { ButtonProps } from 'molecules/Button';
import type { IconIds } from 'molecules/Icon';
import type { ChangeEvent, ChangeEventHandler, ComponentPropsWithoutRef, FC, LegacyRef } from 'react';

export interface InputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'size'> {
  label?: string;
  defaultValue?: string;
  helperText?: string | false;
  error?: boolean;
  errorMessage?: string;
  icon?: IconIds;
  endIcon?: IconIds;
  button?: ButtonProps;
  type?: 'number' | 'text' | 'tel' | 'textarea';
  isPercentage?: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
  placeholder?: string;
  size?: 'small' | 'large' | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}

const inputStyles = cva(
  [
    'text-md',
    'bg-white',
    'text-gray-900',
    'w-full',
    'py-2.5',
    'px-3',
    'border',
    'border-gray-300',
    'shadow-xs',
    'outline-none',
  ],
  {
    variants: {
      hasEndIcon: {
        true: ['!pr-9'],
      },
      hasIcon: {
        true: ['pl-10'],
      },
      hasButton: {
        true: ['pr-28'],
      },
      disabled: {
        true: ['text-gray-500', 'cursor-not-allowed'],
      },
      isLarge: {
        true: 'h-14',
      },
      error: {
        true: [
          '!border-error-700',
          'focus:shadow-[0px_0px_0px_2px_#F04438,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]',
          'placeholder-error-700',
        ],
        false: [
          'outline-none focus:!border-primary-600 focus:shadow-[0px_0px_0px_2px_#79C300,_0px_1px_2px_0px_rgba(16,_24,_40,_0.05)]',
        ],
      },
      isTextarea: {
        true: ['mr-0 h-[100px] !w-full border px-[20px] py-[14px] text-gray-900 shadow-xs'],
      },
    },
  },
);

const helper = cva(['text-gray-600', 'text-xs', 'mt-1'], {
  variants: {
    error: {
      true: ['font-semibold text-error-700'],
    },
  },
});

const iconStyles = cva(['absolute', 'top-3', 'text-gray-900'], {
  variants: {
    hasButton: {
      true: ['top-5'],
    },
    direction: {
      left: ['left-3'],
      right: ['right-3'],
    },
  },
});

const buttonStyles = cva(['absolute', 'right-3', 'top-2']);

const TextField: FC<InputProps> = ({
  label,
  defaultValue,
  helperText,
  error,
  errorMessage,
  icon,
  endIcon,
  name,
  button,
  className,
  disabled,
  onChange,
  onBlur,
  type = 'text',
  isPercentage,
  inputRef,
  placeholder,
  size,
  ...props
}) => {
  const hasIcon = !!icon;
  const hasButton = !!button;
  const hasEndIcon = !!endIcon;
  const [value, setValue] = useState<string>(defaultValue || '');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (isPercentage && parseFloat(target.value) > 100) {
      setValue('100');
    } else {
      setValue(target.value);
    }
  };

  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const inputProps = {
    type,
    className: twMerge(
      inputStyles({
        hasEndIcon,
        isLarge: size === 'large',
        hasIcon,
        hasButton,
        disabled,
        error,
        isTextarea: type === 'textarea',
      }),
    ),
    name,
    disabled,
    value,
    onChange: e => {
      handleChange(e);
      onChange && onChange(e);
    },
    onBlur: e => {
      handleChange(e);
      onBlur && onBlur(e);
    },
    ref: inputRef,
    placeholder: error && !value ? errorMessage || placeholder : placeholder || '',
    ...props,
  };

  let Input = <input {...inputProps} />;
  if (type === 'tel') {
    Input = <PatternFormat format="(###) ###-####" mask="" {...inputProps} />;
  }
  if (type === 'textarea') {
    Input = <textarea {...inputProps} />;
  }

  return (
    <div className={twMerge('w-full mb-4', className)}>
      {label && (
        <label htmlFor={name} className="text-sm font-semibold text-gray-700">
          {label}
        </label>
      )}
      <div className="input relative mt-1">
        {Input}
        {icon && <Icon icon={icon} size={20} className={iconStyles({ hasButton, direction: 'left' })} />}
        {button && <Button className={buttonStyles()} {...button} size="sm" disabled={disabled} />}
        {endIcon && !button && (
          <Icon icon={endIcon} size={20} className={iconStyles({ hasButton, direction: 'right' })} />
        )}
      </div>
      {(helperText || (error && value)) && (
        <p className={twMerge(helper({ error }))}>{error ? errorMessage || helperText : helperText}</p>
      )}
    </div>
  );
};

export default TextField;

import { useMediaQuery } from '@react-hookz/web/esm/useMediaQuery';
import { useEffect, useState } from 'react';

import Image from 'molecules/Image';

import LottieImage from 'components/LottieImage/LottieImage';
import PlayButton from 'components/MediaAsset/PlayButton';

import getVideoId from 'utils/getVideoId';

import screens from 'theme/spacing/screens';

import type { ContentfulItemMediaAsset } from 'graphqlTypes';
import type { FC } from 'react';

type MediaAssetProps = ContentfulItemMediaAsset & {
  className?: string;
};

const MediaAsset: FC<MediaAssetProps> = props => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);
  const isMobile = useMediaQuery(`(max-width: ${screens.sm})`) || false;
  const mobileImage = props?.imageMobile?.file;
  const desktopImage = props?.image?.file;
  const renderImage = isMobile ? mobileImage || desktopImage : desktopImage || mobileImage;
  const isMp4Video = renderImage?.contentType?.startsWith('video'); // === 'video/mp4';
  const isLottie = renderImage?.url?.includes('.json');
  const isYoutube = props?.youtubeEmbedLink;

  useEffect(() => {
    isPlaying && player?.playVideo();
  }, [player, isPlaying]);

  useEffect(() => {
    if (isYoutube) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];

      if (firstScriptTag?.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      window.onYouTubeIframeAPIReady = () => {
        new YT.Player('youtube-iframe', {
          events: {
            onReady: event => setPlayer(event.target),
            onStateChange: event => {
              if (event.data === YT.PlayerState.PLAYING) {
                setIsPlaying(true);
              } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
                setIsPlaying(false);
              }
            },
          },
        });
      };
    }

    if (isMp4Video) {
      const video = document?.getElementById('mp4-video') as HTMLVideoElement;

      video?.addEventListener('pause', () => {
        !video.seeking && setIsPlaying(false);
      });

      video?.addEventListener('play', () => {
        setIsPlaying(true);
      });
    }
  }, []);

  const handlePlayBtnClick = () => {
    if (isMp4Video) {
      const video = document?.getElementById(`mp4-video_${props?.image?.contentful_id}`) as HTMLVideoElement;

      !isPlaying ? video?.play() : video?.pause();
    }

    setIsPlaying(!isPlaying);
  };

  if (isYoutube) {
    return (
      <>
        <PlayButton handleClick={handlePlayBtnClick} isPlaying={isPlaying} />
        <iframe
          src={`https://www.youtube.com/embed/${getVideoId(props?.youtubeEmbedLink)}?enablejsapi=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
          aria-label="embed video"
          {...props}
          id="youtube-iframe"
        />
      </>
    );
  }

  if (isLottie) {
    return <LottieImage url={renderImage?.url} />;
  }

  if (isMp4Video) {
    return (
      <>
        <PlayButton handleClick={handlePlayBtnClick} isPlaying={isPlaying} />
        <video
          className={props?.className || ''}
          poster={props?.thumbnail?.file?.url}
          controls={isPlaying}
          playsInline
          id={`mp4-video_${props?.image?.contentful_id}`}
        >
          <source src={renderImage?.url} type="video/mp4"></source>
          <track kind="captions" />
        </video>
      </>
    );
  }

  return <Image alt={props?.title || ''} {...props} />;
};

export default MediaAsset;

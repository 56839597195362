import { useEffect, useRef } from 'react';
import { useKey } from 'react-use';
import twMerge from 'twMerge';

import Button from 'molecules/Button';
import Form from 'molecules/Form';
import Icon from 'molecules/Icon';
import Image from 'molecules/Image';

import modalStyles, {
  closeModalButton,
  modalColumn,
  modalContainer,
  modalContent,
  modalContentRow,
  modalVideoEmbed,
  modalWrapper,
} from 'components/Modal/modal.styles';
import ReactPortal from 'components/Modal/reactPortal';

import { contentfulProps } from 'utils/emptyTypes';
import getVideoId from 'utils/getVideoId';

import type { ContentfulComponentModal } from 'graphqlTypes';
import type { ButtonProps } from 'molecules/Button';
import type { StaticImage } from 'molecules/Image';
import type { Dispatch, FC, SetStateAction } from 'react';

export interface ModalProps extends ContentfulComponentModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  signInOptions?: ButtonProps['signInOptions'];
  chiliPiperRouter?: string;
  useAlternateForms?: boolean;
}

type CloseBtnProps = {
  styles?: string;
};

const Modal: FC<ModalProps> = ({
  isOpen,
  setIsOpen,
  embedLink,
  formId,
  heading,
  subhead,
  cta,
  signInOptions,
  chiliPiperRouter,
  useAlternateForms,
}) => {
  const type = embedLink ? 'embed' : 'form';
  const hasContent = !!heading || !!subhead || !!cta;
  const modalRef = useRef<HTMLButtonElement>(null);
  const isSignInModal = signInOptions && signInOptions?.length > 0;
  const closeModal = () => setIsOpen(false);

  useKey('Escape', closeModal);

  useEffect(() => {
    if (isOpen) {
      modalRef?.current?.focus();
    }
  }, [isOpen, modalRef]);

  if (!isOpen) {
    return null;
  }

  const CloseBtn: FC<CloseBtnProps> = ({ styles }) => {
    const iconClassName = embedLink ? 'fill-white' : 'fill-black md:fill-white';

    return (
      <button
        className={twMerge(closeModalButton(), styles)}
        tabIndex={0}
        onClick={e => {
          e.stopPropagation();
          closeModal();
        }}
      >
        <Icon icon="x" size={24} className={iconClassName} />
      </button>
    );
  };

  return (
    <ReactPortal wrapperId="modal" wrapperClass={type}>
      <button
        ref={modalRef}
        className={twMerge(modalStyles({ isOpen, type }))}
        onClick={e => {
          e.stopPropagation();
        }}
      />
      <div className={twMerge(modalWrapper({ isOpen }))}>
        <div className={twMerge(modalContainer({ type }))}>
          <CloseBtn styles={`${embedLink ? 'flex' : 'hidden md:flex'} -right-1 -top-8`} />
          <div className={twMerge(modalColumn({ type, isSignInModal }))}>
            <CloseBtn
              styles={`${embedLink ? 'hidden' : 'flex md:hidden'} ${isSignInModal ? 'top-[26px] md:top-3' : 'top-3'} right-6`}
            />
            {embedLink && isOpen && (
              <iframe
                className={twMerge(modalVideoEmbed())}
                src={`https://www.youtube.com/embed/${getVideoId(embedLink)}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                aria-label="embed video"
              />
            )}
            {formId && (
              <Form
                formId={formId}
                heading={heading}
                subheading={subhead}
                chiliPiperRouter={chiliPiperRouter}
                useAlternateForms={useAlternateForms}
                onSuccess={closeModal}
              />
            )}
            {hasContent && !formId && (
              <div className={twMerge(modalContentRow({ type: 'container' }))}>
                <div className={twMerge(modalContentRow({ type: 'content' }))}>
                  {heading && <p className={modalContent({ type: 'heading' })}>{heading}</p>}
                  {subhead && <p className={modalContent({ type: 'subhead' })}>{subhead}</p>}
                </div>
                {cta && (
                  <div className={twMerge(modalContentRow({ type: 'cta' }))}>
                    <Button {...(cta as ButtonProps)} />
                  </div>
                )}
              </div>
            )}
          </div>
          {isSignInModal && (
            <div className="flex h-svh max-w-screen-md flex-wrap justify-center gap-6 overflow-y-scroll bg-gray-100 px-8 py-16 md:h-auto md:overflow-hidden md:p-8">
              {signInOptions.map(option => {
                if (!option) {
                  return null;
                }

                const { id, label, link, icon } = option;

                return (
                  <div
                    key={id}
                    className="flex h-auto min-w-[278px] flex-1 flex-col items-center justify-center gap-4 bg-white p-6 md:h-max md:p-8"
                  >
                    <Image image={icon as StaticImage} alt={label || 'login'} className="h-12" />
                    <Button
                      href={{
                        href: link as string,
                        ...contentfulProps,
                      }}
                      hierarchy="primary"
                      label={label}
                      style={{ width: '100%' }}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </ReactPortal>
  );
};

export default Modal;
